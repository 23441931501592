import { requestApi, requestUnprotected } from './auth.service';

const getMemberByCognitoId = async (cognitoId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/members?cognitoId=${cognitoId}`,
  });
  return data[0];
};

const getMembersByHousehold = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/members`,
  });
  return data;
};

const getMembersInHousehold = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/members/in-household`,
  });
  return data;
};

const getMembersByEmail = async (email) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/members?email=${email}&skipCognitoId=true`,
  });
  return data;
};

const getMemberById = async (memberId, fields, isAuthorized = true) => {
  const makeRequest = isAuthorized ? requestApi : requestUnprotected;
  const { data } = await makeRequest({
    method: 'get',
    url: `/members/${memberId}?fields=${fields}`,
  });
  return data;
};

const updateMember = async (memberData) => {
  const { data } = await requestApi({
    method: 'put',
    url: `/members`,
    data: memberData,
  });
  return data;
};

const updateMemberDuringRegistration = async (memberData) => {
  const { data } = await requestApi({
    method: 'put',
    url: `/members/register`,
    data: memberData,
  });
  return data;
};

const createMember = async (memberData) => {
  try {
    const { data } = await requestApi({
      method: 'post',
      url: `/members`,
      data: memberData,
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const deleteMember = async (memberId) => {
  try {
    const { data } = await requestApi({
      method: 'delete',
      url: `/members/${memberId}`,
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const deleteMemberDuringRegistration = async (memberId) => {
  try {
    const { data } = await requestApi({
      method: 'delete',
      url: `/members/register/${memberId}`,
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export {
  getMemberByCognitoId,
  updateMember,
  createMember,
  getMemberById,
  getMembersByHousehold,
  getMembersInHousehold,
  deleteMember,
  getMembersByEmail,
  deleteMemberDuringRegistration,
  updateMemberDuringRegistration,
};
