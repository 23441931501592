import { isEnv } from '../helpers/Utils';

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = isEnv('dev', 'stg') ? '/dashboard' : '/sharing-requests';
export const sharingRequestsRoot = '/sharing-requests';
export const educationRoot = '/education';
export const paymentCenterRoot = '/payment-center';
export const membershipRoot = '/membership';

export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
  isRegistered: false,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.zionhealth';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

// const testHousehold = '5367098000006210688'
// const firebaseUID = 'j9XYXiVxZgUlN0IZHfIvSibcdSz2'
export const ZOHO_SCOPE = 'ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.coql.READ';

export const grantCode = '1000.2e8b0f64daa83e911e79dd712674f966.9a824926aff2e7f52fda9601f302f959';
