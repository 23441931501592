export const toISOStringWithTimezone = (date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
    date.getMinutes()
  )}:${pad(date.getSeconds())}${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`;
};

export const calcTotalBilledAmount = (lineItemsArr) => {
  let total = 0;
  if (lineItemsArr && lineItemsArr.length > 0) {
    lineItemsArr.forEach((lineItem) => {
      total += lineItem.Amount;
    });
  }
  return total;
};
