/* eslint-disable no-underscore-dangle */
import { requestApi } from './auth.service';
import { parseCardType } from '../helpers/PaymentCenterHelpers';

const _buildSavePaymentProfileBody = (payment, household) => {
  const nameField = payment.nameOnAccount ? payment.nameOnAccount : payment.nameOnCC;
  const fullName = nameField.split(' ');
  const [firstName, lastName = ''] = fullName;
  const creditCardIssuer = payment.ccNum ? parseCardType(payment.ccNum) : null;
  return {
    firstName,
    lastName,

    phone: household.Phone,
    email: household.Household_Email,
    householdNo: household.Household_No,

    ...payment,

    achName: payment?.nameOnAccount,
    achNickname: payment?.bankName,
    achRouting: payment?.routingNum,
    achAccount: payment?.accountNum,

    isAuthNet: !payment?.accountNum,
    creditCardIssuer,
  };
};
const getZippyPaymentProfile = async (payment, household) => {
  const res = await requestApi({
    method: 'get',
    url: `/finances/payment-profile`,
  });
  return res.data;
};

const savePaymentProfile = async (payment, household) => {
  const body = _buildSavePaymentProfileBody(payment, household);

  const res = await requestApi({
    method: 'post',
    url: `/finances/payment-profile`,
    data: body,
  });
  return res.data;
};

export { savePaymentProfile, getZippyPaymentProfile };
