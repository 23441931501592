import { requestApi } from './auth.service';

const getNotificationsList = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/notifications`,
  });
  return data;
};

const markNotificationAsViewed = async (notificationId) => {
  const { data } = await requestApi({
    method: 'put',
    url: `/notifications/${notificationId}`,
  });
  return data;
};

export { getNotificationsList, markNotificationAsViewed };
