import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { savePaymentProfile, getZippyPaymentProfile } from 'services/payment-method.service';
import { getZippy, setAuthNet, setZippy } from '../../helpers/localStorageHelper';

const getPaymentProfile = createAsyncThunk('paymentProfile/get', async () => {
  const paymentProfile = await getZippyPaymentProfile();
  setZippy(paymentProfile);
  return paymentProfile;
});

const updatePaymentProfile = createAsyncThunk(
  'paymentProfile/update',
  async (payment, { getState, rejectWithValue }) => {
    const household = getState().household.record;

    try {
      const { paymentProfile, authNet } = await savePaymentProfile(payment, household);
      setZippy(paymentProfile);
      setAuthNet(authNet);
      return { paymentProfile, cardPaymentProfile: authNet };
    } catch (err) {
      console.log('Error while updating payment method');
      console.log(err);
      setZippy({});
      setAuthNet({});
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  paymentProfile: getZippy(),
  paymentProfileError: '',
  error: '',
  loading: false,
};

const paymentProfileSlice = createSlice({
  name: 'paymentProfile',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentProfile.fulfilled, (state, action) => {
        state.paymentProfile = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentProfile = null;
      })
      .addCase(updatePaymentProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePaymentProfile.fulfilled, (state, action) => {
        const { paymentProfile, cardPaymentProfile } = action.payload;
        state.paymentProfile = paymentProfile;
        state.loading = false;
      })
      .addCase(updatePaymentProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentProfile = null;
      });
  },
});

export { updatePaymentProfile, getPaymentProfile };
export default paymentProfileSlice.reducer;
