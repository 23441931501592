import { toast } from 'react-toastify';

/* eslint-disable import/prefer-default-export */
export const notify = (result, position, message) => {
  switch (result) {
    case 'success':
      return toast.success(message, { position });
    case 'failed':
      return toast.error(message, { position });
    case 'warning':
      return toast.warning(message, { position });
    default:
      return null;
  }
};
