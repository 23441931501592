import { requestApi } from './auth.service';

const getSharingRequests = async (householdId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/sharing-requests?householdId=${householdId}`,
  });
  return data;
};

const getSingleSharingRequest = async (sharingRequestId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/sharing-requests/${sharingRequestId}`,
  });
  return data[0];
};

const getLastSharingRequest = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/sharing-requests/last`,
  });
  return data[0];
};

const createSharingRequest = async (sharingRequest) => {
  const { data } = await requestApi({
    method: 'post',
    url: `/sharing-requests`,
    data: sharingRequest,
  });
  return data;
};

export { getSharingRequests, getSingleSharingRequest, createSharingRequest, getLastSharingRequest };
