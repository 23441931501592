import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearLocalStorage, getCurrentUser } from 'helpers/localStorageHelper';
import { addCredentials, removeCredentials } from 'helpers/authHelper';
import { getMemberByCognitoId, updateMember } from 'services/members.service';
import { toISOStringWithTimezone } from 'helpers/ZohoHelpers';
import * as authService from 'services/auth.service';

const login = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
  const { email, password, newPassword } = payload;
  try {
    const { accessToken, refreshToken, idToken, userId } = await authService.loginUser(email, password, newPassword);
    addCredentials({ accessToken, refreshToken, idToken });
    const member = await getMemberByCognitoId(userId);
    if (!member?.id) {
      return rejectWithValue('Member auth data is corrupted');
    }
    addCredentials({ member });

    return member;
  } catch (err) {
    console.error('auth: login thunk error:', err);
    removeCredentials();
    if (err.message === 'Could not find matching records.') {
      return rejectWithValue('No user found with that email. Please try again.');
    }
    return rejectWithValue(err.message);
  }
});

const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email, { rejectWithValue }) => {
  try {
    await authService.forgotPassword(email);
    return 'success';
  } catch (err) {
    return rejectWithValue(err.message || 'Invalid data');
  }
});

const resetPassword = createAsyncThunk('auth/resetPassword', async (payload, { rejectWithValue }) => {
  const { email, newPassword, verificationCode } = payload;
  try {
    await authService.resetPassword(email, verificationCode, newPassword);
    return 'success';
  } catch (err) {
    return rejectWithValue(err.message || 'Invalid data');
  }
});

const registerUser = createAsyncThunk('auth/register', async (payload, { rejectWithValue, dispatch }) => {
  const { name, email, password, memberId, firstName, lastName, migrated, affiliate } = payload;
  try {
    await authService.registerUser({
      name,
      email,
      password,
      memberId,
      firstName,
      lastName,
      migrated,
      ...(affiliate && { affiliate }),
      date: toISOStringWithTimezone(new Date()),
    });
    // return dispatch(login({ email, password }));
    return true;
  } catch (err) {
    console.error('auth: register thunk error:', err);
    return rejectWithValue(err.message ?? 'Error registering user');
  }
});

const logout = createAsyncThunk('auth/logout', async () => {
  clearLocalStorage();
  window.location.assign('/auth/login');
});

const initialState = {
  currentUser: getCurrentUser(),
  forgotUserMail: '',
  newPassword: '',
  verificationCode: '',
  loading: false,
  error: '',
  registerSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetForgotPasswordState: (state) => {
      state.forgotUserMail = '';
    },
    clearAuthError: (state) => {
      state.error = '';
    },
    resetRegisterState: (state) => {
      state.registerSuccess = false;
      window.location.href = 'https://zionhealthshare.org/';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.error = '';
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.currentUser = null;
        state.error = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.forgotUserMail = action.payload;
        state.error = '';
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.forgotUserMail = null;
        state.error = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.newPassword = action.payload;
        state.error = '';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.newPassword = null;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
        state.registerSuccess = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.currentUser = null;
        state.error = '';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export { login, registerUser, forgotPassword, resetPassword, logout };
export const { resetForgotPasswordState, clearAuthError, resetRegisterState } = authSlice.actions;
export default authSlice.reducer;
