const removeCredentials = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('zh_member');
};
const addCredentials = ({ accessToken, refreshToken, idToken, member }) => {
  if (accessToken) localStorage.setItem('accessToken', accessToken);
  if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
  if (idToken) localStorage.setItem('idToken', idToken);
  if (member) localStorage.setItem('zh_member', JSON.stringify(member));
};

// eslint-disable-next-line import/prefer-default-export
export { addCredentials, removeCredentials };
