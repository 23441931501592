import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createAttachments, getAttachmentsList } from '../../services/attachments.service';

const fetchAttachmentsList = createAsyncThunk('attachments/fetchList', async (sharingRequestId) =>
  getAttachmentsList(sharingRequestId)
);

const uploadAttachments = createAsyncThunk('attachments/upload', async (payload, { dispatch }) => {
  const { recordId, files } = payload;
  await createAttachments(recordId, files);
  dispatch(fetchAttachmentsList(recordId));
});

const initialState = {
  attachmentsList: [],
  loading: false,
  uploading: false,
  error: '',
};

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttachmentsList.pending, (s) => {
        s.loading = true;
      })
      .addCase(fetchAttachmentsList.fulfilled, (s, a) => {
        s.loading = false;
        s.attachmentsList = a.payload;
        s.error = null;
      })
      .addCase(fetchAttachmentsList.rejected, (s, a) => {
        s.loading = false;
        s.attachmentsList = null;
        s.error = a.error;
      })
      .addCase(uploadAttachments.pending, (s) => {
        s.uploading = true;
      })
      .addCase(uploadAttachments.fulfilled, (s) => {
        s.uploading = false;
      })
      .addCase(uploadAttachments.rejected, (s, a) => {
        s.uploading = false;
        s.error = a.error;
      });
  },
});

export { fetchAttachmentsList, uploadAttachments };
export default attachmentsSlice.reducer;
