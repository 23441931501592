import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getProvidersLedgersBySharingRequestId,
  getSingleProvidersLedger,
} from '../../services/providers-ledgers.service';

export const fetchProvidersLedgers = createAsyncThunk('providersLedgers/fetchList', async (sharingRequestId) =>
  getProvidersLedgersBySharingRequestId(sharingRequestId)
);

export const fetchSingleProvidersLedger = createAsyncThunk(
  'providersLedgers/fetchSingle',
  async (providersLedgerId) => {
    const providersLedger = await getSingleProvidersLedger(providersLedgerId);
    if (!providersLedger || providersLedger.length === 0) {
      throw new Error(`Cannot find providers ledger with id ${providersLedgerId}`);
    }
    return providersLedger;
  }
);

const initialState = {
  ledgers: [],
  single: {},
  loading: false,
  error: '',
};

const providersLedgersSlice = createSlice({
  name: 'providersLedgers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProvidersLedgers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProvidersLedgers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';
        state.ledgers = action.payload;
      })
      .addCase(fetchProvidersLedgers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error || 'Oops';
        state.ledgers = [];
      })
      .addCase(fetchSingleProvidersLedger.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleProvidersLedger.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';
        state.single = action.payload;
      })
      .addCase(fetchSingleProvidersLedger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message || action.error || 'Oops';
        state.single = {};
      });
  },
});

export default providersLedgersSlice.reducer;
