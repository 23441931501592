/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'services/sharing-request.service';
import { getActiveSharingRequests, getCompletedSharingRequests } from '../../helpers/localStorageHelper';
import { startListening } from '../listenerMiddleware';

const createSharingRequest = createAsyncThunk('sharingRequests/create', async (data, { rejectWithValue }) => {
  try {
    return await api.createSharingRequest(data);
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

const fetchSharingRequests = createAsyncThunk('sharingRequests/fetch', async (householdId) => {
  const sharingRequests = await api.getSharingRequests(householdId);
  const active = [];
  const completed = [];
  if (sharingRequests.length > 0) {
    sharingRequests.forEach((sharingRequest) => {
      if (
        sharingRequest.Processing_Status === 'Completed' ||
        sharingRequest.Processing_Status === 'Closed Pending Member Action'
      ) {
        completed.push(sharingRequest);
      } else {
        active.push(sharingRequest);
      }
    });
  }
  return { active, completed };
});

const fetchSingleSharingRequest = createAsyncThunk(
  'sharingRequests/fetchSingle',
  async (sharingRequestId, { rejectWithValue }) => {
    const sharingRequest = await api.getSingleSharingRequest(sharingRequestId);
    return sharingRequest ?? rejectWithValue(`Cannot find sharing request with id ${sharingRequestId}`);
  }
);

const fetchLastSharingRequest = createAsyncThunk('sharingRequests/fetchLast', async (_, { rejectWithValue }) => {
  const sharingRequest = await api.getLastSharingRequest();
  return sharingRequest ?? rejectWithValue(`Cannot find sharing request`);
});

const initialState = {
  active: getActiveSharingRequests(),
  completed: getCompletedSharingRequests(),
  single: {},
  last: null,
  loading: false,
  error: '',
};

const sharingRequestsSlice = createSlice({
  name: 'sharingRequests',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSharingRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSharingRequests.fulfilled, (state, action) => {
        const { active, completed } = action.payload;
        state.active = active;
        state.completed = completed;
        state.loading = false;
      })
      .addCase(fetchSharingRequests.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(createSharingRequest.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createSharingRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.new = action.payload ? action.payload[0]?.details : null;
      })
      .addCase(createSharingRequest.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchSingleSharingRequest.fulfilled, (state, action) => {
        state.single = action.payload;
        state.loading = false;
      })
      .addCase(fetchSingleSharingRequest.rejected, (state, action) => {
        state.error = action.error?.message || action.error;
        state.loading = false;
      })
      .addCase(fetchLastSharingRequest.fulfilled, (state, action) => {
        state.last = action.payload;
      })
      .addCase(fetchLastSharingRequest.rejected, (state) => {
        state.last = null;
      });
  },
});

startListening({
  actionCreator: createSharingRequest.fulfilled,
  effect: async (action, listenerApi) => {
    const householdId = listenerApi.getState().authUser.currentUser?.Account_Name?.id;
    listenerApi.dispatch(fetchSharingRequests(householdId));
  },
});

export { fetchSharingRequests, fetchSingleSharingRequest, createSharingRequest, fetchLastSharingRequest };
export default sharingRequestsSlice.reducer;
