import { requestApi } from './auth.service';

const getSingleHousehold = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/households`,
  });
  return data;
};
const getActualizedHousehold = async () => {
  const { data } = await requestApi({
    method: 'post',
    url: `/households/actualize`,
  });
  return data;
};

const updateHousehold = async (fields) => {
  console.log('updateHousehold', fields?.Tobacco);
  const { data } = await requestApi({
    method: 'put',
    url: '/households',
    data: fields,
  });
  console.log('updateHousehold - SUCCESS', data);
  return data;
};

export { getSingleHousehold, updateHousehold, getActualizedHousehold };
