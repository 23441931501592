import { removeCredentials } from 'helpers/authHelper';
import {
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';

const getItemFromLocalStorage = (key, defaultValue = null) => {
  let value = defaultValue;
  try {
    const item = localStorage.getItem(key);
    if (item) {
      value = JSON.parse(item);
    }
  } catch (error) {
    console.log(`>>>>: src/helpers/localStorageHelper.js : getItemFromLocalStorage -> error`, error);
    value = defaultValue;
  }
  return value;
};

const setItemInLocalStorage = (key, value) => {
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.log(`>>>>: src/helpers/localStorageHelper.js : setItemInLocalStorage -> error`, error);
  }
};

export const getDirection = () => {
  const direction = getItemFromLocalStorage('direction', defaultDirection);
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  if (localValue === 'rtl' || localValue === 'ltr') {
    setItemInLocalStorage('direction', localValue);
  }
};

export const getCurrentColor = () => getItemFromLocalStorage(themeColorStorageKey, defaultColor);

export const setCurrentColor = (color) => setItemInLocalStorage(themeColorStorageKey, color);

export const getCurrentRadius = () => getItemFromLocalStorage(themeRadiusStorageKey, 'rounded');

export const setCurrentRadius = (radius) => setItemInLocalStorage(themeRadiusStorageKey, radius);

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    const currentLanguage = localStorage.getItem('currentLanguage');
    if (currentLanguage && localeOptions.some((option) => option.id === currentLanguage)) {
      language = currentLanguage;
    }
  } catch (error) {
    console.log(`>>>>: src/helpers/localStorageHelper.js : getCurrentLanguage -> error`, error);
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => setItemInLocalStorage('currentLanguage', locale);

export const getZionItem = (key, defaultValue) => getItemFromLocalStorage(`zh_${key}`, defaultValue);
export const setZionItem = (key, value) => setItemInLocalStorage(`zh_${key}`, value);

export const getCurrentUser = () => getZionItem('member');
export const setCurrentUser = (user) => setZionItem('member', user);

export const getHousehold = () => getZionItem('household');
export const setHousehold = (household) => setZionItem('household', household);
export const getBaseMembership = () => getZionItem('saseMembership');
export const setBaseMembership = (baseMembership) => setZionItem('baseMembership', baseMembership);
export const getMembers = () => getZionItem('members');
export const setMembers = (members) => setZionItem('members', members);

export const getZippy = () => getZionItem('zippy');
export const setZippy = (zippy) => setZionItem('zippy', zippy);

export const getAuthNet = () => getZionItem('authNet');
export const setAuthNet = (authNet) => setZionItem('authNet', authNet);

export const getActiveSharingRequests = () => getZionItem('active_sharing_requests');
export const setActiveSharingRequests = (requests) => setZionItem('active_sharing_requests', requests);

export const getCompletedSharingRequests = () => getZionItem('completed_sharing_requests');
export const setCompletedSharingRequests = (requests) => setZionItem('completed_sharing_requests', requests);

export const setDontShowNoticeFlag = (show, memberId) => setZionItem(`dont_show_upload_notice_${memberId}`, show);
export const getDontShowNoticeFlag = (memberId) => getZionItem(`dont_show_upload_notice_${memberId}`, false);

export const clearLocalStorage = () => {
  removeCredentials();
  setCurrentUser();
  setHousehold();
  setMembers();
  setZippy();
  setAuthNet();
  setActiveSharingRequests();
  setCompletedSharingRequests();
  setBaseMembership();
};
