import { singleProviderRequestFields } from 'constants/zohoFieldsDefs';
import { requestApi } from './auth.service';

const getProvidersLedgersBySharingRequestId = async (sharingRequestId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/finances/provider-ledgers/sharing-request/${sharingRequestId}`,
  });
  return data;
};

const getSingleProvidersLedger = async (providerLedgerId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/finances/provider-ledgers/${providerLedgerId}?fields=${singleProviderRequestFields.toString()}`,
  });
  return data[0];
};

export { getProvidersLedgersBySharingRequestId, getSingleProvidersLedger };
