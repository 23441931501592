import { requestApi } from './auth.service';

const getAttachmentsList = async (sharingRequestId) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/attachments/${sharingRequestId}`,
  });
  return data;
};

const createAttachments = async (sharingRequestId, files) => {
  const { data } = await requestApi({
    method: 'post',
    url: `/attachments/${sharingRequestId}`,
    data: { files },
  });
  return data;
};

const getAttachment = async (folderName, recordId, fileName) => {
  const { data } = await requestApi({
    method: 'get',
    url: `/attachments/${folderName}/${recordId}/${fileName}`,
  });
  return data;
};

export { getAttachmentsList, createAttachments, getAttachment };
