export const membershipRecordFieldsForZippy = [
  'Monthly_Contribution_Amount',
  'Additional_Services_Notes',
  'RxShare',
  'Rx_Share_Start_Date',
  'Rx_Share_Withdrawal_Date',
  'Teladoc',
  'Teladoc_Start_Date',
  'Teladoc_Withdrawal_Date',
  'Teledoc_Amount',
  'LifeWorks',
  'LifeWorks_Start_Date',
  'LifeWorks_Withdrawal_Date',
  'Lifeworks_Amount',
  'Preventive',
  'Preventive_Start_Date',
  'Preventive_Withdrawal_Date',
  'Tobacco',
  'Tobacco_Start_Date',
  'Tobacco_Quit_Date',
  'Admin_Fee',
  'Household',
];

export const memberRecordFieldsForLogin = [
  'Age',
  'Date_of_Birth',
  'Email',
  'First_Name',
  'Gender',
  'Head_of_House',
  'HIPAA_Signed_Date',
  'Household_ID',
  'Account_Name',
  'Last_Name',
  'Last_Seen_in_Portal',
  'Mailing_City',
  'Mailing_Country',
  'Mailing_State',
  'Mailing_Street',
  'Mailing_Street_1',
  'Mailing_Zip',
  'Membership_ID',
  'Member_Status',
  'Phone',
  'Preferred_Name',
  'Other_City',
  'Other_Country',
  'Other_State',
  'Other_Street',
  'Other_Street_2',
  'Other_Zip',
  'Tobacco_User',
  'Relationship_to_Primary_Member',
  // 'Membership',
];

export const singleSharingRequestFields = [
  'Appeal_Status',
  'Name',
  'Determination',
  'Processing_Status',
  'Member',
  'Created_Time',
  'Remaining_IUA_Balance',
  'Provider_Ledgers',
  'Address',
  'Providers_Seen',
  'Preventive_Determination',
  'Member_Name',
  'Need_Type',
  'Need_Reference_Number1',
];

export const singleProviderRequestFields = [
  'Ammount',
  'Name',
  'Total_Discounts',
  'Total_Member_And_Provider_Payments',
  'Total_Payment_Discounts',
  'Reimbursement_Amount_Due_Member',
  'Remaining_Balance_Due_Provider',
  'Line_Items',
  'Payments_and_Credits',
  'Shareable_Running_Balance',
  'Total_Shared_Amount',
  'Household',
];
