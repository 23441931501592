import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNotificationsList, markNotificationAsViewed } from '../../services/notifications.service';
import { NotificationTypes } from '../../constants/notifications';
import { startListening } from '../listenerMiddleware';

const fetchNotificationsList = createAsyncThunk('notifications/fetchList', async () => getNotificationsList());

//
// const uploadAttachments = createAsyncThunk('attachments/upload', async (payload, { dispatch }) => {
//   const { recordId, files } = payload;
//   await createAttachments(recordId, files);
//   dispatch(fetchAttachmentsList(recordId));
// });

// const fetchNotificationsList = createAsyncThunk('notifications/fetch', async () => {});

const exampleNotificationsList = [
  {
    id: 'yakes-tam-id',
    level: 'warning',
    type: NotificationTypes.DOCUMENT_NEEDED,
    text: 'Document needed for {sharing request}',
    context: { idType: 'sharingRequest', id: '5028463000149203079' },
    dismissible: true,
    dismissed: false,
  },
  {
    id: 'yakes-tam-id-2',
    level: 'danger',
    type: NotificationTypes.PAYMENT_METHOD_EXPIRED,
    text: 'Document needed for sharing request {sharingRequestId}',
    context: { idType: 'sharingRequest', id: '5028463000149203079' },
    dismissible: false,
    dismissed: false,
  },
];

const initialState = {
  list: [],
  loading: false,
  uploading: false,
  error: '',
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    dismissNotification: (state, action) => {
      const notificationId = action.payload;
      const selected = state.list.find((n) => n.id === notificationId);
      selected.dismissed = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsList.pending, (s) => {
        s.loading = true;
      })
      .addCase(fetchNotificationsList.fulfilled, (s, a) => {
        s.loading = false;
        s.list = a.payload;
        s.error = null;
      })
      .addCase(fetchNotificationsList.rejected, (s, a) => {
        s.loading = false;
        s.list = [];
        s.error = a.error;
      });
  },
});

export const { dismissNotification } = notificationsSlice.actions;

startListening({
  actionCreator: dismissNotification,
  effect: (action) => {
    markNotificationAsViewed(action.payload);
  },
});

export { fetchNotificationsList };
export default notificationsSlice.reducer;
