import { requestApi } from './auth.service';

const getContributionsByHouseholdId = async () => {
  const { data } = await requestApi({
    method: 'get',
    url: `/finances/contributions`,
  });
  return data;
};

const updateContribution = async (contributionData) => {
  const { data } = await requestApi({
    method: 'put',
    url: `/finances/contributions`,
    data: contributionData,
  });
  return data;
};

const createIUAContribution = async (contributionData) => {
  const { data } = await requestApi({
    method: 'post',
    url: `/finances/contributions/IUA`,
    data: contributionData,
  });
  return data;
};

export { getContributionsByHouseholdId, updateContribution, createIUAContribution };
