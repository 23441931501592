import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { addCredentials, removeCredentials } from 'helpers/authHelper';
import { notify } from '../helpers/Toast';

const tokenRefresh = async () => {
  try {
    const currentToken = localStorage.getItem('idToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (currentToken) {
      const storageInfo = jwt_decode(currentToken);
      const tokenExp = storageInfo.exp;

      if (tokenExp < Math.floor(Date.now() / 1000)) {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${currentToken}`,
        };

        const body = {
          idToken: currentToken,
          refreshToken,
        };

        const config = {
          baseURL: process.env.REACT_APP_API_BASE,
          method: 'POST',
          url: 'auth/refresh',
          data: body,
          headers,
        };

        const {
          data: { accessToken, refreshToken: _refreshToken, idToken },
        } = await axios(config);

        addCredentials({ accessToken, refreshToken: _refreshToken, idToken });

        return idToken;
      }
      return currentToken;
    }

    return currentToken;
  } catch (error) {
    if (error.response && error.response?.status === 401) {
      removeCredentials();
      window.location.href = '/';
    }
    throw error;
  }
};

const requestUnprotected = async (params) => {
  const { method, body, injectHeaders, ...otherParam } = params;

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    ...injectHeaders,
  };
  const objMeta = {
    baseURL: process.env.REACT_APP_API_BASE,
    method,
    headers,
    data: body,
    ...otherParam,
  };

  try {
    return await axios(objMeta);
  } catch (error) {
    const { statusCode } = error?.response?.data;
    if (statusCode === 401 && window.location.pathname !== '/auth/login') {
      removeCredentials();
      window.location.href = '/';
    }
    throw error?.response?.data || error;
  }
};

const requestApi = async (params) => {
  const { method, body, injectHeaders, ...otherParam } = params;
  const REFRESH_TOKEN = localStorage.getItem('refreshToken');
  const ACCESS_TOKEN = await tokenRefresh();

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${ACCESS_TOKEN}`,
    'Refresh-Token': REFRESH_TOKEN,
    ...injectHeaders,
  };

  const objMeta = {
    baseURL: process.env.REACT_APP_API_BASE,
    method,
    headers,
    data: body,
    ...otherParam,
  };

  try {
    return await axios(objMeta);
  } catch (error) {
    const { message, statusCode } = error?.response?.data;
    if (statusCode === 401) {
      removeCredentials();
      window.location.href = '/';
    }
    if (statusCode === 403) {
      notify('failed', 'top-center', `You don't have permission to perform this operation`);
      // if (window.location.pathname !== '/dashboard') window.location.href = '/dashboard';
      console.error(message || error);
    }
    throw error?.response?.data || error;
  }
};

const loginUser = async (email, password, newPassword) => {
  try {
    const {
      data: { accessToken, refreshToken, idToken, userId },
    } = await requestUnprotected({
      method: 'post',
      body: { email, password, newPassword },
      url: '/auth/login',
    });
    return { accessToken, refreshToken, idToken, userId };
  } catch (err) {
    if (err?.statusCode === 406) {
      window.location.href = `/auth/password-expired/${email}`;
      return null;
    }
    if (err?.statusCode === 412) {
      window.location.href = `/auth/login?email=${encodeURIComponent(email)}`;
      return null;
    }
    throw err;
  }
};

/**
 * @param userData
 * @param userData.name
 * @param userData.email
 * @param userData.password
 * @param userData.firstName
 * @param userData.lastName
 * @param userData.memberId
 * @param userData.date
 * @returns {Promise<any>}
 */
const registerUser = async (userData) => {
  const { data } = await requestUnprotected({
    method: 'post',
    body: userData,
    url: '/auth/register',
  });
  return data;
};

const forgotPassword = async (email) => {
  const { data } = await requestUnprotected({
    method: 'post',
    body: { email },
    url: '/auth/forgot-password',
  });
  return data;
};

const resetPassword = async (email, verificationCode, password) => {
  const { data } = await requestUnprotected({
    method: 'post',
    body: { email, verificationCode, password },
    url: '/auth/reset-password',
  });
  return data;
};

export { loginUser, registerUser, tokenRefresh, requestApi, requestUnprotected, forgotPassword, resetPassword };
